<template>
  <ion-page>
    <Main>
      <template #subHeader>
        <ion-toolbar>
          <ion-row v-if="order" class="ion-padding-p ion-padding-vertical">
            <ion-col size="1" class="ion-no-padding">
              <span>
                <ion-icon
                  @click="back"
                  class="back-arrow"
                  :icon="arrowBack"
                ></ion-icon>
              </span>
            </ion-col>
            <ion-col class="ion-no-padding">
              <ion-row class="ion-no-padding">
                <ion-col size="12" class="ion-no-padding">
                  <p class="title">
                    Orden de Producción {{ $filters.folio(order.id) }}
                  </p>
                </ion-col>
                <ion-col size="12" class="ion-no-padding">
                  <p class="subtitle">
                    Fecha de Solicitud:
                    <span class="ion-primary-color ion-margin-start">
                      {{ $filters.timeFrom(order.date) }}</span
                    >
                  </p>
                </ion-col>
              </ion-row>
            </ion-col>
            <ion-col size="3" class="ion-text-end">
              <span
                class="status"
                :class="{
                  solicitado: order.status == 'solicitado',
                  produccion: order.status != 'solicitado',
                }"
              >
                {{ $filters.capitalize(order.status) }}
              </span>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </template>
      <template #content>
        <ion-row class="cards-container ion-padding-p  ion-padding-vertical">
          <ion-col class="ion-no-padding">
            <ion-card class="ion-no-padding ion-no-margin">
              <ion-card-content>
                <ion-row class="tab-head">
                  <ion-col size="6">
                    <p>{{ order.quantity }} Artículos</p>
                  </ion-col>
                  <ion-col size="6">
                    <p>
                      Fecha:
                      <span class="ion-primary-color pull-right">
                        {{ moment(order.d_date).format("DD MMM YYYY") }}
                      </span>
                    </p>
                  </ion-col>
                </ion-row>
                <template v-for="(product, p) in order.products" :key="p">
                  <ion-row v-if="product.mquantity > 0" class="tab-row">
                    <ion-col size="6">
                      <ion-row class="ion-no-padding">
                        <ion-col size="2">
                          <p>
                            <span class="number">
                              {{ product.mquantity }}
                            </span>
                          </p>
                        </ion-col>
                        <ion-col size="10">
                          <p class="tab-row-sub">
                            {{
                              product.name
                                .replace(product.capacity, "")
                                .replace("Lts.", "")
                            }}
                          </p>
                        </ion-col>
                        <ion-col
                          v-for="(materia, m) in product.materia"
                          :key="m"
                          size="12"
                        >
                          <p class="subtitle2 margin-left-col2">
                            {{ materia.name }}
                            <span class="pull-right"
                              >{{ parseFloat(materia.supplied) }} kgs</span
                            >
                          </p>
                        </ion-col>
                      </ion-row>
                    </ion-col>
                    <ion-col size="2">
                      <p class="p4 tab-row-sub">{{ product.capacity }} Lts.</p>
                    </ion-col>
                    <ion-col size="4">
                      <ion-button
                        type="button"
                        class="ion-margin-bottom btn-register"
                        expand="block"
                        color="outlined"
                        @click="select(product.id_product)"
                      >
                        Registrar
                      </ion-button>
                    </ion-col>
                  </ion-row>
                  <ion-row
                    v-for="(done, d) in product.done_p"
                    :key="d"
                    class="tab-row"
                  >
                    <ion-col size="6">
                      <ion-row class="ion-no-padding">
                        <ion-col size="2">
                          <ion-icon
                            class="checkicon"
                            :icon="checkmark"
                          ></ion-icon>
                        </ion-col>
                        <ion-col size="10">
                          <p class="tab-row-sub">
                            {{
                              product.name
                                .replace(product.capacity, "")
                                .replace("Lts.", "")
                            }}
                          </p>
                        </ion-col>
                        <ion-col size="12">
                          <p class="subtitle2 margin-left-col2">
                            Producto {{ $filters.folio(done.id) }}
                            <span class="pull-right">
                              {{ done.segunda == 0 ? "Primera" : "Segunda" }}
                            </span>
                          </p>
                        </ion-col>
                      </ion-row>
                    </ion-col>
                    <ion-col size="2">
                      <p class="p4 tab-row-sub">{{ product.capacity }} Lts.</p>
                    </ion-col>
                    <ion-col size="4">
                      <span
                        v-if="done.status == 'desechado'"
                        class="status danger"
                      >
                        {{ $filters.capitalize(done.status) }}
                      </span>
                      <ion-button
                        v-else
                        type="button"
                        class="ion-margin-bottom btn-edit"
                        expand="block"
                        color="outlined"
                        @click="edit(done.id)"
                      >
                        <ion-icon :icon="create"></ion-icon>
                      </ion-button>
                    </ion-col>
                  </ion-row>
                </template>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </template>
    </Main>
  </ion-page>
</template>

<script>
import useOrder from "@/use/useOrder";
import {
  IonPage,
  IonRow,
  IonCol,
  IonIcon,
  IonCard,
  IonToolbar,
  IonButton,
  IonCardContent,
  alertController,
} from "@ionic/vue";
import { arrowBack, checkmark, create } from "ionicons/icons";
import useApi from "@/use/useApi";
import { ref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import Main from "@/components/system/main.vue";
import moment from "moment-business-days";
import { watch } from "@vue/runtime-core";

export default {
  name: "Order detail",
  components: {
    Main,
    IonPage,
    IonRow,
    IonCol,
    IonCard,
    IonToolbar,
    IonButton,
    IonCardContent,
    IonIcon,
  },
  setup() {
    const router = useRouter();
    const { params } = useRoute();
    const { order, getOrder } = useOrder();
    getOrder(params.id, "rotomolde");
    /*if (!order.value) {
      getOrder(params.id, 'rotomolde');
    } else if (Object.keys(order.value).length === 0) {
      getOrder(params.id, 'rotomolde');
    }*/
    const leftover = ref(false);
    const controlers = ref({
      tab: "new",
    });
    const orders = ref([{}]);
    const materia = ref({});
    const { post } = useApi();

    function back() {
      router.back();
    }
    function getMateria() {
      materia.value = {};
      if (order.value.products == undefined) {
        return 0;
      }
      order.value.products.forEach((prod) => {
        prod.materia.forEach((mat) => {
          if (materia.value[mat.id] == undefined) {
            materia.value[mat.id] = mat;
          }
        });
      });
    }
    watch(() => order.value, getMateria);

    async function confirm() {
      const data = await post(
        "app/rotomolde/confirm",
        {
          id: params.id,
          leftover: leftover.value,
          materia: materia.value,
          end: true,
        },
        "Validando..."
      );
      if (data === null) {
        const alert = await alertController.create({
          class: "warning",
          header: "Error",
          message: "Se genero un error en servidor",
        });
        await alert.present();
      } else {
        if (data.error === false) {
          back();
        }
      }
    }

    async function waitConfirm() {
      const alert = await alertController.create({
        header: "Terminar proceso",
        message: "¿Desea dar el proceso por terminado?",
        buttons: [
          {
            text: "Continuar",
            role: "continue",
            cssClass: "primary",
            handler: () => {
              confirm();
            },
          },
          {
            text: "Cancelar",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Confirm Cancel:", blah);
            },
          },
        ],
      });
      await alert.present();
    }

    function select(product) {
      router.push("/rotomolde/orders/" + order.value.id + "/" + product + "/");
    }
    function edit(product) {
      router.push(
        "/rotomolde/orders/" + order.value.id + "/edit/" + product + "/"
      );
    }
    return {
      leftover,
      order,
      orders,
      controlers,
      arrowBack,
      checkmark,
      create,
      moment,
      materia,
      back,
      waitConfirm,
      select,
      edit,
    };
  },
};
</script>

<style>
ion-card {
  border-radius: 15px;
  border: 1px solid var(--ion-color-primary);
  box-shadow: none;
  font-size: 12px;
}
ion-card-title {
  color: var(--ion-color-medium);
  font-size: 14px;;
}
ion-toolbar {
  --background: var(--ion-color-light);
}
ion-button::part(native) {
  text-transform: none;
  font-size: 15px;
}
.btn-register::part(native) {
  background: #fff;
  box-shadow: none;
  border: 1px solid var(--ion-color-medium-tint);
  color: var(--ion-color-medium-tint);
  padding: 6px 20px;
  transform: translateY(1px);
}
.btn-edit::part(native) {
  background: #fff;
  box-shadow: none;
  border: 1px solid var(--ion-color-medium-tint);
  color: var(--ion-color-medium-tint);
  padding: 20px 10px;
  transform: translateY(1px);
  width: auto;
  float: right;
}
.checkicon {
  font-size: 20px;
  padding: 8px 2px;
  min-width: 32px;
  background: var(--ion-color-light);
  --ionicon-stroke-width: 70px;
}
</style>
